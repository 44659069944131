import React from 'react'
import { PUBLICATIONS } from '../../components/constant'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
import IMGArticle from '../../images/new/pub.png'
const Acticles = () => {
  return (
    <div>
      <Layout>
        <SEO title="Acticles" />
        <PageStructure
          title="Acticles"
          desc="TESTIG"
          mode={PUBLICATIONS}
          image={IMGArticle}
        />
      </Layout>
    </div>
  )
}

export default Acticles
